<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0 d-inline">{{ pageName }}</h3>
              </b-card-header>
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(file_uuid)="row">
                  <a href="javascript:void(0)" @click="previewPdf(row.value)">
                    {{ row.item.file_name }}
                  </a>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
import { api, helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      items: [],
      fields: [
        { key: 'ym', label: '給与年月' },
        { key: 'file_uuid', label: 'ファイル' },
      ],
      currentPage: 1,
      perPage: config.rowsPerPage,
    }
  },
  computed: {
    pageName: function() {
      return common.getPageName(this.$route.name);
    },
    totalRows: function() {
      return this.items.length;
    },
  },
  created: function () {
    document.title = this.pageName;
    this.getPaymentSlipList();
  },
  methods: {
    getPaymentSlipList() {
      const vm = this;
      ajax.fetchGet(config.api.employee.payment_slip).then(data => {
        vm.items = data;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
    previewPdf(uuid) {
      api.viewAttachment(uuid, this);
    },
  },
}
</script>